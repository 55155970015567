import { Injectable } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import { saveFile } from 'shared/services/file-download-helper';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { FileUploadServiceProxy, BranchListDto } from 'shared/service-proxies/service-proxies';
import { StopfreightTypes, StopFreightType, FileStoreType, FileStoreFileType, StopState, StepAction, InspecStatus, BaseLookupDto, RateType } from '@shared/AppEnums';
import * as _ from 'lodash';

@Injectable({
	providedIn: 'root',
})
export class OpsAccessService {
	stopfreighTypesList: StopFreightType[] = [];
	stopfreightTypes = StopfreightTypes;
	inspecStatus = InspecStatus;
	stepAction = StepAction;
	fromViewState: string;
	constructor(private http: HttpClient, private fileUploadService: FileUploadServiceProxy) {}

	public getAllRateTypes(): BaseLookupDto[] {
		const outPutArray: BaseLookupDto[] = [];
		const StopRateType = new BaseLookupDto();
		StopRateType.id = RateType.StopRate;
		StopRateType.name = 'Agent Rate';
		outPutArray.push(StopRateType);

		const adHocRateType = new BaseLookupDto();
		adHocRateType.id = RateType.AdhocRate;
		adHocRateType.name = 'Ad-hoc Rate';
		outPutArray.push(adHocRateType);

		const addGeneralRate = new BaseLookupDto();
		addGeneralRate.id = RateType.GeneralRate;
		addGeneralRate.name = 'General Rate';
		outPutArray.push(addGeneralRate);

		return outPutArray;
	}
	public getInspecStatusLookupData(): BaseLookupDto[] {
		const outPutArray: BaseLookupDto[] = [];

		const newIdle = new BaseLookupDto();
		newIdle.id = this.inspecStatus.Idle;
		newIdle.name = 'Idle';
		outPutArray.push(newIdle);

		const newInProgress = new BaseLookupDto();
		newInProgress.id = this.inspecStatus.InProgress;
		newInProgress.name = 'In Progress';
		outPutArray.push(newInProgress);

		const newCompleted = new BaseLookupDto();
		newCompleted.id = this.inspecStatus.Completed;
		newCompleted.name = 'Completed';
		outPutArray.push(newCompleted);
		return outPutArray;
	}

	public getStepActionListLookupData(): BaseLookupDto[] {
		const outPutArray: BaseLookupDto[] = [];

		const newTakePhoto = new BaseLookupDto();
		newTakePhoto.id = this.stepAction.TakePhoto;
		newTakePhoto.name = 'Take Photo';
		outPutArray.push(newTakePhoto);

		const newGetCount = new BaseLookupDto();
		newGetCount.id = this.stepAction.GetCount;
		newGetCount.name = 'Get Count';
		outPutArray.push(newGetCount);

		const newGetSignature = new BaseLookupDto();
		newGetSignature.id = this.stepAction.GetSignature;
		newGetSignature.name = 'Get Signature';
		outPutArray.push(newGetSignature);

		const newInfo = new BaseLookupDto();
		newInfo.id = this.stepAction.Information;
		newInfo.name = 'Information';
		outPutArray.push(newInfo);

		return outPutArray;
	}
	public getFreightTypesByInspectionId(branchList: BranchListDto[], selectedBranchId: number): StopFreightType[] {
		const selectedBranch = _.find(branchList, (l) => l.id === selectedBranchId);
		if (selectedBranch) {
			this.stopfreighTypesList.length = 0;
			const newSeaFreight = new StopFreightType();
			newSeaFreight.id = this.stopfreightTypes.SeaFreight;
			newSeaFreight.name = 'Sea Freight';
			const newAirFreight = new StopFreightType();
			newAirFreight.id = this.stopfreightTypes.AirFreight;
			newAirFreight.name = 'Air Freight';

			switch (selectedBranch.stopType) {
				case this.stopfreightTypes.SeaAndAirFreight:
					this.stopfreighTypesList.push(newSeaFreight);
					this.stopfreighTypesList.push(newAirFreight);
					break;
				case this.stopfreightTypes.SeaFreight:
					this.stopfreighTypesList.push(newSeaFreight);
					break;
				case this.stopfreightTypes.AirFreight:
					this.stopfreighTypesList.push(newAirFreight);
					break;
				default:
					break;
			}
		}

		return this.stopfreighTypesList;
	}
	public downloadReportWithParameters(action: string, data) {
		let url_ = AppConsts.remoteServiceBaseUrl + '/api/Report/' + action;
		url_ = url_.replace(/[?&]$/, '');

		const content_ = JSON.stringify(data);

		const options_: any = {
			body: content_,
			observe: 'response',
			responseType: 'blob',
			headers: new HttpHeaders({
				Authorization: 'Bearer ' + abp.auth.getToken(),
				'Content-Type': 'application/json',
				Accept: 'application/json',
			}),
		};

		this.http.request('post', url_, options_).subscribe((response: any) => {
			const blob = new Blob([response['body']], { type: 'application/octet-stream' });
			const fileName = response.headers.get('x-filename');
			saveFile(blob, fileName);
		});
	}
	public downloadGetReportById(action: string, id: number | null | undefined): any {
		abp.notify.info('Your download request has been sent.', 'Please wait...');
		let url_ = AppConsts.remoteServiceBaseUrl + '/api/Report/' + action + '?';
		if (id !== undefined) {
			url_ += 'id=' + encodeURIComponent('' + id) + '&';
		}
		url_ = url_.replace(/[?&]$/, '');

		const options_: any = {
			responseType: 'blob',
			observe: 'response',
			headers: new HttpHeaders({
				Authorization: 'Bearer ' + abp.auth.getToken(),
			}),
		};

		this.http.request('get', url_, options_).subscribe(
			(response: any) => {
				const blob = new Blob([response['body']], { type: 'application/octet-stream' });
				const fileName = response.headers.get('x-filename');
				if (fileName) {
					saveFile(blob, fileName);
				}
			},
			async (error) => {
				const jsonError = await new Response(error.error).json();
				abp.message.warn(jsonError.result, 'Error getting file');
			}
		);
	}
	public downloadFileById(id: number | null | undefined, fileStoreFileType: FileStoreFileType | null | undefined, fileStoreType: FileStoreType | null | undefined): any {
		abp.notify.info('Your download request has been sent.', 'Please wait...');
		let url_ = AppConsts.remoteServiceBaseUrl + '/api/FileUpload/Download?';

		if (id !== undefined) {
			url_ += 'id=' + encodeURIComponent('' + id) + '&';
		}
		if (fileStoreFileType !== undefined) {
			url_ += 'fileStoreFileType=' + encodeURIComponent('' + fileStoreFileType) + '&';
		}
		if (fileStoreType !== undefined) {
			url_ += 'fileStoreType=' + encodeURIComponent('' + fileStoreType) + '&';
		}
		url_ = url_.replace(/[?&]$/, '');

		const options_: any = {
			responseType: 'blob',
			observe: 'response',
			headers: new HttpHeaders({
				Authorization: 'Bearer ' + abp.auth.getToken(),
			}),
		};

		this.http.request('get', url_, options_).subscribe((response: any) => {
			const blob = new Blob([response['body']], { type: 'application/octet-stream' });
			const fileName = response.headers.get('x-filename');
			if (fileName) {
				saveFile(blob, fileName);
			} else {
				abp.message.warn('No files/documents found for the selected stop', 'No Files Found');
			}
		});
	}
	public setViewState(newViewState: string) {
		this.fromViewState = newViewState;
	}
	public getViewState(): string {
		return this.fromViewState;
	}

	public downloadZipFileById(id: number | null | undefined, fileStoreFileType: FileStoreFileType | null | undefined, fileStoreType: FileStoreType | null | undefined): any {
		abp.notify.info('Your download request has been sent.', 'Please wait...');
		let url_ = AppConsts.remoteServiceBaseUrl + '/api/FileUpload/DownloadZipFile?';

		if (id !== undefined) {
			url_ += 'id=' + encodeURIComponent('' + id) + '&';
		}
		if (fileStoreFileType !== undefined) {
			url_ += 'fileStoreFileType=' + encodeURIComponent('' + fileStoreFileType) + '&';
		}
		if (fileStoreType !== undefined) {
			url_ += 'fileStoreType=' + encodeURIComponent('' + fileStoreType) + '&';
		}
		if (fileStoreType !== undefined) {
			url_ += 'para=' + encodeURIComponent('' + 'na') + '&';
		}
		url_ = url_.replace(/[?&]$/, '');

		const options_: any = {
			responseType: 'blob',
			observe: 'response',
			headers: new HttpHeaders({
				Authorization: 'Bearer ' + abp.auth.getToken(),
			}),
		};

		this.http.request('get', url_, options_).subscribe((response: any) => {
			const blob = new Blob([response['body']], { type: 'application/octet-stream' });
			const fileName = response.headers.get('x-filename');
			if (fileName) {
				saveFile(blob, fileName);
			} else {
				abp.message.warn('No files/documents found for the selected stop', 'No Files Found');
			}
		});
	}

	public downloadTemplateById(id: number | null | undefined): any {
		let url_ = AppConsts.remoteServiceBaseUrl + '/api/FileUpload/GetFileTemplate?';
		if (id !== undefined) {
			url_ += 'id=' + encodeURIComponent('' + id) + '&';
		}
		url_ = url_.replace(/[?&]$/, '');

		const options_: any = {
			responseType: 'blob',
			observe: 'response',
			headers: new HttpHeaders({
				Authorization: 'Bearer ' + abp.auth.getToken(),
			}),
		};

		this.http.request('get', url_, options_).subscribe((response: any) => {
			const blob = new Blob([response['body']], { type: 'application/octet-stream' });
			const fileName = response.headers.get('x-filename');
			saveFile(blob, fileName);
		});
	}
}

import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AbpHttpInterceptor } from 'abp-ng2-module';

import * as ApiServiceProxies from './service-proxies';

@NgModule({
     providers: [
          ApiServiceProxies.RoleServiceProxy,
          ApiServiceProxies.SessionServiceProxy,
          ApiServiceProxies.TenantServiceProxy,
          ApiServiceProxies.UserServiceProxy,
          ApiServiceProxies.TokenAuthServiceProxy,
          ApiServiceProxies.AccountServiceProxy,
          ApiServiceProxies.ConfigurationServiceProxy,
          ApiServiceProxies.CompanyServiceProxy,
          ApiServiceProxies.StopServiceProxy,
          ApiServiceProxies.FileStoreServiceProxy,
          ApiServiceProxies.FileUploadServiceProxy,
          ApiServiceProxies.NotesServiceProxy,
          ApiServiceProxies.BranchServiceProxy,
          ApiServiceProxies.InspectionTypeServiceProxy,
          ApiServiceProxies.ReqDocsServiceProxy,
          ApiServiceProxies.ReportServiceProxy,
          ApiServiceProxies.RatesServiceProxy,
          ApiServiceProxies.InvoiceServiceProxy,
          ApiServiceProxies.InspecTempServiceProxy,
          ApiServiceProxies.InTypeServiceProxy,
          ApiServiceProxies.NotificationServiceProxy,
          { provide: HTTP_INTERCEPTORS, useClass: AbpHttpInterceptor, multi: true },
     ],
})
export class ServiceProxyModule {}

import { Injectable } from '@angular/core';
import { SessionServiceProxy, SapsBranchEditDto, UserLoginInfoDto, TenantLoginInfoDto, ApplicationInfoDto, GetCurrentLoginInformationsOutput, BranchListDto, ReqDocsDto, InspectionTypeDto, BaseNotificationDto, InspectionTypeDtoStopType, StopEditDtoStopType } from '@shared/service-proxies/service-proxies';
import { AbpMultiTenancyService } from 'abp-ng2-module';
import { UserAccountType, UseraccountType, FromStopToInvoice, StopfreightTypes, StopFreightType } from '@shared/AppEnums';
import * as _ from 'lodash';
@Injectable()
export class AppSessionService {
	public stopfreightTypes = StopfreightTypes;
	public stopfreighTypesList: StopFreightType[] = [];

	selectedBranchId = 0;
	selectedSapsBranch: SapsBranchEditDto | null | undefined = undefined;

	fromStopToInvoice = new FromStopToInvoice();

	private _user: UserLoginInfoDto;
	private _tenant: TenantLoginInfoDto;
	private _application: ApplicationInfoDto;
	private _branches: BranchListDto[];
	private _reqDocs: ReqDocsDto[];
	private _inspectionTypes: InspectionTypeDto[];

	constructor(private _sessionService: SessionServiceProxy, private _abpMultiTenancyService: AbpMultiTenancyService) {}

	getCurrentUserStopNotification(): BaseNotificationDto {
		const newStopNoti = new BaseNotificationDto();
		newStopNoti.receiveEmail = true;
		newStopNoti.email = this._user.emailAddress;
		newStopNoti.name = this._user.name + ' ' + this._user.surname;

		return newStopNoti;
	}

	getSelectedBranchId(): number {
		return this.selectedBranchId;
	}

	setSelectedBranchId(id: number) {
		this.selectedBranchId = id;
	}

	getSelectedSapsBranch(): SapsBranchEditDto {
		return this.selectedSapsBranch;
	}

	setSelectedSapsBranch(sapsBranch: SapsBranchEditDto) {
		this.selectedSapsBranch = sapsBranch;
	}
	getFromStopToInvoiceId(): FromStopToInvoice {
		return this.fromStopToInvoice;
	}

	setFromStopToInvoiceId(stopId: number, invoiceId: number, isFromStop: boolean) {
		this.fromStopToInvoice.InvoiceId = invoiceId;
		this.fromStopToInvoice.StopId = stopId;
		this.fromStopToInvoice.IsFromStop = isFromStop;
	}
	get application(): ApplicationInfoDto {
		return this._application;
	}

	get user(): UserLoginInfoDto {
		return this._user;
	}

	get userId(): number {
		return this.user ? this.user.id : null;
	}

	get tenant(): TenantLoginInfoDto {
		return this._tenant;
	}

	get tenantId(): number {
		return this.tenant ? this.tenant.id : null;
	}

	getShownLoginName(): string {
		const userName = this._user.userName;
		if (!this._abpMultiTenancyService.isEnabled) {
			return userName;
		}

		return (this._tenant ? this._tenant.tenancyName : '.') + '\\' + userName;
	}

	getBranchList(): BranchListDto[] {
		return this._branches;
	}
	getReqDocsList(): ReqDocsDto[] {
		return this._reqDocs;
	}
	getInspectionTypesList(): InspectionTypeDto[] {
		return this._inspectionTypes;
	}
	getInspectionTypesListByStopType(stopType: StopEditDtoStopType): InspectionTypeDto[] {
		let outPut: InspectionTypeDto[];

		if (stopType == null) {
			return outPut;
		}

		if (stopType == this.stopfreightTypes.SeaAndAirFreight) {
			outPut = _.filter(this._inspectionTypes, (l) => l.stopType === this.stopfreightTypes.SeaFreight || l.stopType === this.stopfreightTypes.AirFreight);

			return outPut;
		}
		if (stopType == this.stopfreightTypes.SeaFreight) {
			outPut = _.filter(this._inspectionTypes, (l) => l.stopType === this.stopfreightTypes.SeaFreight || l.stopType === this.stopfreightTypes.SeaAndAirFreight);

			return outPut;
		}
		if (stopType == this.stopfreightTypes.AirFreight) {
			outPut = _.filter(this._inspectionTypes, (l) => l.stopType === this.stopfreightTypes.AirFreight || l.stopType === this.stopfreightTypes.SeaAndAirFreight);

			return outPut;
		}
		if (stopType == this.stopfreightTypes.SAPSReleaseSeaFreight) {
			outPut = _.filter(this._inspectionTypes, (l) => l.stopType === this.stopfreightTypes.SAPSReleaseSeaFreight);

			return outPut;
		}
		return this._inspectionTypes;
	}
	getstopfreighTypesList(): StopFreightType[] {
		if (this.stopfreighTypesList.length === 0) {
			const newSeaFreight = new StopFreightType();
			newSeaFreight.id = this.stopfreightTypes.SeaFreight;
			newSeaFreight.name = 'Sea Freight';

			const newAirFreight = new StopFreightType();
			newAirFreight.id = this.stopfreightTypes.AirFreight;
			newAirFreight.name = 'Air Freight';

			const newBoth = new StopFreightType();
			newBoth.id = this.stopfreightTypes.SeaAndAirFreight;
			newBoth.name = 'Sea & Air Freight';
			this.stopfreighTypesList.push(newSeaFreight);
			this.stopfreighTypesList.push(newAirFreight);
			this.stopfreighTypesList.push(newBoth);
		}

		return this.stopfreighTypesList;
	}
	getStooTypesList(addSeaAndAir): StopFreightType[] {
		if (abp.auth.isGranted('SAPS User')) {
			const newSapsSeaFreight = new StopFreightType();
			newSapsSeaFreight.id = this.stopfreightTypes.SAPSReleaseSeaFreight;
			newSapsSeaFreight.name = 'SAPS Release, Sea Freight';

			this.stopfreighTypesList.push(newSapsSeaFreight);

			return this.stopfreighTypesList;
		}

		if (abp.auth.isGranted('System User') && this.stopfreighTypesList.length === 0) {
			const newSeaFreight = new StopFreightType();
			newSeaFreight.id = this.stopfreightTypes.SeaFreight;
			newSeaFreight.name = 'Sea Freight';

			const newAirFreight = new StopFreightType();
			newAirFreight.id = this.stopfreightTypes.AirFreight;
			newAirFreight.name = 'Air Freight';

			this.stopfreighTypesList.push(newSeaFreight);
			this.stopfreighTypesList.push(newAirFreight);

			if (abp.auth.isGranted('Pages.Operations.SapsRelease')) {
				const newSapsSeaFreight = new StopFreightType();
				newSapsSeaFreight.id = this.stopfreightTypes.SAPSReleaseSeaFreight;
				newSapsSeaFreight.name = 'SAPS Release, Sea Freight';

				this.stopfreighTypesList.push(newSapsSeaFreight);
			}
		}

		if (addSeaAndAir) {
			const newBoth = new StopFreightType();
			newBoth.id = this.stopfreightTypes.SeaAndAirFreight;
			newBoth.name = 'Sea & Air Freight';
			this.stopfreighTypesList.push(newBoth);
		}

		return this.stopfreighTypesList;
	}
	getAccountTypesByUserAccountType(type: UserAccountType): UseraccountType[] {
		const useraccountTypes: UseraccountType[] = [];

		const company = new UseraccountType();
		company.id = UserAccountType.Company;
		company.name = 'Agent';

		//SAPS
		const saps = new UseraccountType();
		saps.id = UserAccountType.Saps;
		saps.name = 'Saps';
		switch (type) {
			case UserAccountType.System:
				const system = new UseraccountType();
				system.id = UserAccountType.System;
				system.name = 'System';
				useraccountTypes.push(system);
				useraccountTypes.push(company);

				useraccountTypes.push(saps);

				const inspector = new UseraccountType();
				inspector.id = UserAccountType.Inspector;
				inspector.name = 'Inspector';
				useraccountTypes.push(inspector);
				break;

			case UserAccountType.Company:
				useraccountTypes.push(company);
				break;
			case UserAccountType.Saps:
				useraccountTypes.push(saps);
				break;
		}

		return useraccountTypes;
	}

	init(): Promise<boolean> {
		return new Promise<boolean>((resolve, reject) => {
			this._sessionService
				.getCurrentLoginInformations()
				.toPromise()
				.then(
					(result: GetCurrentLoginInformationsOutput) => {
						this._application = result.application;
						this._user = result.user;
						this._tenant = result.tenant;
						this._branches = result.branches;
						this._reqDocs = result.reqDocs;
						this._inspectionTypes = result.inspectionTypes;
						resolve(true);
					},
					(err) => {
						reject(err);
					}
				);
		});
	}

	changeTenantIfNeeded(tenantId?: number): boolean {
		if (this.isCurrentTenant(tenantId)) {
			return false;
		}

		abp.multiTenancy.setTenantIdCookie(tenantId);
		location.reload();
		return true;
	}

	private isCurrentTenant(tenantId?: number) {
		if (!tenantId && this.tenant) {
			return false;
		} else if (tenantId && (!this.tenant || this.tenant.id !== tenantId)) {
			return false;
		}

		return true;
	}
}

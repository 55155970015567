export class AppConsts {

    static remoteServiceBaseUrl: string;
    static appBaseUrl: string;
    static appBaseHref: string; // returns angular's base-href parameter value if used during the publish
    static localeMappings: any = [];
    static readonly userManagement = {
        defaultAdminUserName: 'admin',
        Admin: 'Admin',
        Editor : 'Editor',
        CompanyAdmin : 'Company Admin',
        CompanyUser : 'Company User'

    };

    static readonly localization = {
        defaultLocalizationSourceName: 'NFE'
    };

    static readonly authorization = {
        encryptedAuthTokenName: 'enc_auth_token'
    };
}

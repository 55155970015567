import { CommonModule } from '@angular/common';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { RouterModule } from '@angular/router';

import { AppSessionService } from './session/app-session.service';
import { AppUrlService } from './nav/app-url.service';
import { AppAuthService } from './auth/app-auth.service';
import { AppRouteGuard } from './auth/auth-route-guard';
import { MaterialInput } from 'shared/directives/material-input.directive';
import { OpsAccessService } from '@shared/services/opsaccess.service';
// import { UserNotificationHelper } from '@shared/Helpers/UserNotificationHelper';

import { LocalizePipe } from '@shared/pipes/localize.pipe';
import { MomentFromNowPipe } from '@shared/pipes/moment-from-now.pipe';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CdkTableModule } from '@angular/cdk/table';
import { CdkTreeModule } from '@angular/cdk/tree';
import { BlockDirective } from './directives/block.directive';
import { BusyDirective } from './directives/busy.directive';
import { EqualValidator } from './directives/equal-validator.directive';

@NgModule({
     imports: [CommonModule, RouterModule],
     declarations: [MaterialInput, LocalizePipe, MomentFromNowPipe, BlockDirective, BusyDirective, EqualValidator],
     exports: [MaterialInput, LocalizePipe, MomentFromNowPipe, BlockDirective, BusyDirective, EqualValidator, CdkTableModule, CdkTreeModule, DragDropModule, ScrollingModule],
})
export class SharedModule {
     static forRoot(): ModuleWithProviders<SharedModule> {
          return {
               ngModule: SharedModule,
               providers: [
                    AppSessionService,
                    AppUrlService,
                    AppAuthService,
                    AppRouteGuard,
                    OpsAccessService,
                    //   UserNotificationHelper
               ],
          };
     }
}
